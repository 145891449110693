%transitionAll {
	transition: all .25s ease-in-out;
}

%commonTsProximaNovaRegular {
	font-family: $tk-proxima-nova;
	font-style: normal;
	font-weight: 400;
}

%commonTsP22UndergroundLight {
	font-family: $tk-p22-underground;
	font-style: normal;
	font-weight: 300;
}

%commonSectionStyles {
	padding: 15rem 0;

  @include mq(max, 768px) {
    padding: 11rem 0;
  }

  @include mq(max, 480px) {
    padding: 7rem 0;
  }
}

%commonSmallSectionPadding {
	padding: 11rem 0;

	@include mq(max, 768px) {
		padding: 9rem 0;
	}

	@include mq(max, 480px) {
		padding: 7rem 0;
	}
}

%commonHeroHeadingStyles {
	font-family: $tk-p22-underground;
	font-style: normal;
	font-weight: 300;
	font-size: 6rem;
    line-height: 1.3;
	margin: 0 0 4.5rem;

	@include mq(max, 1024px) {
		font-size: 5rem;
	}

	@include mq(max, 540px) {
		font-size: 3.5rem;
	}

	@include mq(max, 320px) {
		font-size: 2.6rem;
		margin: 0 0 1.5rem;
	}
}

%commonHeadingStyles {
	font-size: 6rem;
	font-weight: 600;
	margin: 0 0 5rem;
	position: relative;
	z-index: 1;
	line-height: normal;

	@include mq(max, 480px) {
		font-size: 3.5rem;
		margin: 0 0 3rem;
	}
}

%commonThinHeadingStyles {
	color: $navy;
	font-family: $tk-p22-underground;
	font-style: normal;
	font-weight: 300;
	font-size: 3.6rem;
	letter-spacing: .2rem;
	line-height: 1.2;
	z-index: 1;

	@include mq(max, 1024px) {
		font-size: 3.3rem;
	}

	@include mq(max, 768px) {
		font-size: 3rem;
	}

	@include mq(max, 480px) {
		font-size: 2.4rem;
	}
}

%commonTextStyles {
	font-size: 1.8rem;
	color: $silver;
	max-width: 50rem;
	margin: 0 0 4.5rem;
	position: relative;
	z-index: 1;

	@include mq(max, 1024px) {
		font-size: 1.6rem;
	}

	@include mq(max, 480px) {
		font-size: 1.4rem;
		margin: 0 0 3.5rem;
	}
}

%commonBackgroundStyles {
	position: absolute;
	top: 50%;
	right: 0;
	transform: translateY(-50%);
	margin: 0;
	z-index: 0;
    overflow: hidden;

	@include mq(max, 768px) {
		opacity: .25;
	}
}

%commonNavStyles {
	display: flex;

	@include mq(max, 800px) {
		flex-direction: column;
		align-items: center;
	}
}

%commonMainNavItemStyles {
	@extend %transitionAll;
	font-family: $work;
	font-size: 1.4rem;
	margin: 0 0 0 3rem;
	line-height: 8rem;
	border-bottom: 0rem solid transparent;
	z-index: 1;
}

%commonAzureGradientBackground {
	background: linear-gradient($white 60%, $azure);
}

%commonScrollLinkStyles {
	display: block;
	display: flex;
	align-items: center;
	font-size: 1.6rem;

	> .chevron {
		margin: 0 0 0 1rem;
	}
}

%commonListStyles {
	padding: 0 0 0 2rem;
	margin: 0 0 2rem;

	> .item {
		padding: 0 0 0 1rem;
		margin: 0 0 1.5rem;
	}
}

%commonColumnStyles {
	columns: 2 20rem;
	column-gap: 10rem;

	@include mq(max, 1024px) {
		column-gap: 5rem;
	}

	@include mq(max, 700px) {
		columns: 1;
	}
}

%commonMyLeaseHeroIcon {
	max-width: 25rem;
	svg {
		width: 100%;
		margin-right: 30rem;
		.cls-1 {
		  fill: #ffffff;
		}
	}
	@include mq(max, 1024px) {
		max-width: 20rem;
	}
	@include mq(max, 480px) {
		max-width: 14rem;
	}
	@include mq(max, 320px) {
		max-width: 11rem;
	}
}
