.personal {
  @extend .container;
  @extend .container.-inner;

  > .inner {
    @extend %commonSectionStyles;
    position: relative;

    > .heading {
      @extend %commonHeadingStyles;
    }

    > .text {
      @extend %commonTextStyles;
    }

    > .background {
      @extend %commonBackgroundStyles;

      > svg {
        max-width: 45rem;
      }
    }
  }
}