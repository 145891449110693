.lease {
  @extend %commonSectionStyles;
  @extend %commonAzureGradientBackground;

  padding: 10rem 0;

  > .inner {
    @extend .container;
    @extend .container.-inner;

    > .intro {
      position: relative;

      > .background {
        @extend %commonBackgroundStyles;
        max-width: 50rem;
        max-height: 50rem;
        transform: none;
        top: 0;

        .lease-simple-logo {
              left: 50%;
              position: absolute;
              top: 50%;
              width: 85px;
              height: 85px;
              margin-top: -45px;
              margin-left: -28px;
          svg {
            width: 100%;
            height: 100%;
          }
        }
      }

      > .heading {
        @extend %commonHeadingStyles;
      }
    
      > .text {
        @extend %commonTextStyles;
        @extend %commonTsProximaNovaRegular;
        svg {
          max-width: 27rem;
          margin: 0 23rem 4rem 0;
        }
      }

      > .controls {
        z-index: 1;
        position: relative;
        display: flex;
        margin: 0 0 20rem;

        @include mq(max, 480px) {
          flex-direction: column;
          margin: 0 0 10rem;
        }

        > .linkColumn {
          margin-right: 5rem;

          @include mq(max, 480px) {
            margin: 0 0 3rem;
          }

          > .label {
            @extend %commonThinHeadingStyles;
            margin: 0 0 1.5rem;
          }
        }
      }
    }
  
    > .partners {
      > .heading {
        font-family: $work;
        font-size: 3.6rem;
        letter-spacing: .2rem;
        font-weight: 300;
        margin: 0 0 4rem;

        @include mq(max, 480px) {
          margin: 0 0 6rem;
        }
      }

      > .item {
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 13rem;
        margin: 0;
        justify-content: space-between;

        @include mq(max, 480px) {
          height: 10rem;
          margin: 0 0 4rem;
        }
    
        &:last-child {
          align-items: flex-start;
          
          @include mq(max, 480px) {
            align-items: center;
          }
        }

        > .heading {
          @extend %commonTsProximaNovaRegular;
          font-size: 2.4rem;
          margin: 0 0 3rem;
        }

        > .title {
          > a {
            color: rgba($black, .3);

            @include mq(min, 1024px) {
              @include slidingUnderline(rgba($black, .3), .1rem)
            }
          }
        }
      }

      .button {
        @extend %commonTsP22UndergroundLight;
        color: #ffffff;
        border: none;
        background-image: linear-gradient(to right, #1582e4 0%, #1890ee 51%, #279ff2 100%);
        svg {
          transform: rotate(-90deg);

          polygon {
            fill: #ffffff;
          }
        }
      }

      // > .list {
      //   display: flex;
      //   justify-content: space-between;

      //   @include mq(max, 480px) {
      //     flex-direction: column;
      //   }

      //   > .item {
      //     display: flex;
      //     flex-direction: column;
      //     align-items: center;
      //     height: 13rem;
      //     margin: 0;
      //     justify-content: space-between;

      //     @include mq(max, 480px) {
      //       height: 10rem;
      //       margin: 0 0 4rem;
      //     }
      
      //     &:last-child {
      //       align-items: flex-start;
            
      //       @include mq(max, 480px) {
      //         align-items: center;
      //       }
      //     }

      //     > .heading {
      //       font-family: $work;
      //       font-size: 2.4rem;
      //       font-weight: 300;
      //       margin: 0 0 3rem;
      //     }

      //     > .title {
      //       > a {
      //         color: rgba($black, .3);

      //         @include mq(min, 1024px) {
      //           @include slidingUnderline(rgba($black, .3), .1rem)
      //         }
      //       }
      //     }
      //   }
      // }
    }
  }  
}