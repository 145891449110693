.generic {
  @extend .container;
  @extend .container.-inner;

  > .top {
    position: relative;
    margin: 15rem 0 4rem;

    @include mq(max, 700px) {
      padding: 0 0 6rem;
    }

    &.-twoColumns {
      @extend %commonColumnStyles;
    }

    &.-noIntro {
      > .column {
        > .heading {
          margin: 0 0 15rem;

          @include mq(max, 480px) {
            margin: 0 0 5rem;
          }
        }
      }
    }

    &:not(.-twoColumns) {
      > .column {
        > .text {
          padding: 0 0 6rem;
        }
      }
    }

    > .column {
      break-inside: avoid;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      > .heading {
        @extend %commonHeroHeadingStyles;
      }

      > .text {
        @extend %commonTextStyles;
        margin: 0;
        max-width: unset;
      }

      > .scroll {
        @extend %commonScrollLinkStyles;
        color: $navy;
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 2;
      }
    }
  }

  > .content {
    @extend %commonSmallSectionPadding;

    &.-twoColumns {
      @extend %commonColumnStyles;
    }

    > .block {
      break-inside: avoid;
      margin: 0 0 5.5rem;

      @include mq(max, 768px) {
        margin: 0 0 4rem;
      }

      > .heading {
        @extend %commonThinHeadingStyles;
        margin: 0 0 2rem;
      }

      > .text {
        margin: 0 0 2rem;
      }

      > .list {
        @extend %commonListStyles;

        > .node {
          > .link {
            @extend %transitionAll;
            color: $silver;

            @include mq(min, 1024px) {
              @include slidingUnderline(darken($silver, 20%), .1rem)
            }
          }
        }
      }
    }
  }
}