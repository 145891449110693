.join {
  @extend .container;
  @extend .container.-inner;
  
  > .inner {
    @extend %commonSmallSectionPadding;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    > .heading {
      @extend %commonThinHeadingStyles;
      font-size: 2rem;
      margin: 0 0 2.5rem;
    }
  }
}