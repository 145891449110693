.about {
  @extend .container;
  @extend .container.-inner;

  > .row {
    @extend %commonSmallSectionPadding;

    > .column {
      &.-retailers {

        &.-left {

        }

        &.-right {
          justify-content: flex-start;
          align-items: flex-start;
        }
      }

      &.-left {
        @extend .col;
        width: 100%;
        padding-left: 5rem;

        @include mq(max, 480px) {
          padding: 0 2rem;
        }
      }

      &.-right {
        @extend .col;
        @extend .col.-twoFifths;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
      }

      > .title {
        @extend %commonThinHeadingStyles;
        
      }

      > .text {
        @extend %commonTextStyles;
        max-width: none;
        margin: 0;
      }

      > .list {
        padding: 0 0 0 3rem;

        > .node {
          @extend %commonTextStyles;
          margin: 0;
          padding: 0 0 1rem 1rem;
        }
      }

      > svg:not(:last-child) {
        margin: 0 0 5rem;
      }
    }
  }
}