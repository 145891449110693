@mixin clearfix() {
  &:before,
  &:after {
      content: "";
      display: table;
  }
  &:after {
      clear: both;
  }
}

@mixin centerElement {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@mixin makeRow($negativeMargin, $breakpointColumn) {
  display: flex;
  margin-left: -$negativeMargin;
  margin-right: -$negativeMargin;
  max-width: 100%;

  @include mq(max, $breakpointColumn) {
    flex-direction: column;
  }
}

@mixin slidingUnderline($hoverColor, $underlineHeight) {
  position: relative;
  text-decoration: none;
  -webkit-backface-visibility: hidden;

  &:hover {
    color: $hoverColor;

    &:before {
      visibility: visible;
      transform: scaleX(1);
    }
  }
  
  &:before {
    content: "";
    position: absolute;
    width: 100%;
    height: $underlineHeight;
    bottom: -.4rem;
    left: 0;
    background-color: $hoverColor;
    visibility: hidden;
    transform: scaleX(0);
    transition: $transition;
  }
}

@mixin placeholder {
  &::-webkit-input-placeholder {@content}
  &:-moz-placeholder           {@content}
  &::-moz-placeholder          {@content}
  &:-ms-input-placeholder      {@content}
  &::placeholder               {@content}
}