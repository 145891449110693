html {
  font-size: 62.5%;

  * {
    box-sizing: border-box;
  }
}

body {
  font-size: 1.4rem; // it calculates to 14px
  line-height: 3rem;
  font-weight: 400;
  font-family: $work;
  margin: 0;
  color: $silver;
  background: $white;
  width: 100%;

  @include mq(max, 768px) {
    &[data-state='menu-expanded'] {
      position: fixed;
      overflow: hidden;
    }
  }
}

h1, h2, h3, h4 {
  color: $navy;
  font-family: $nimbus;
}

h1 {
  font-size: 4.8rem;

  @include mq(max, 768px) {
    font-size: 3.8rem;
  }

  @include mq(max, 480px) {
    font-size: 2.6rem;
  }
}

h2 {
  font-size: 4rem;
  font-weight: normal;

  @include mq(max, 768px) {
    font-size: 3rem;
  }

  @include mq(max, 480px) {
    font-size: 2.4rem;
  }
}

h3 {
  font-size: 3rem;

  @include mq(max, 768px) {
    font-size: 2rem;
  }

  @include mq(max, 480px) {
    font-size: 1.8rem;
  }
}

h4 {
  font-size: 2rem;

  @include mq(max, 768px) {
    font-size: 1.7rem;
  }

  @include mq(max, 480px) {
    font-size: 1.5rem;
  }
}

section {
  overflow-x: hidden;

  &:focus {
    outline: none;
  }
}

img {
  max-width: 100%; 
  display: block; 
  height: auto;
}

a {
  text-decoration: none;
  color: $blue;
}

hr {
  border: 0;
  margin: 0;
  position: relative;
  width: 100%;
  max-width: 120rem;
  padding: .2rem 0;
  border-bottom: .1rem solid rgba($black, .1);

  &.-accent {
    &:after {
      content: '';
      height: .3rem;
      width: 23rem;
      position: absolute;
      bottom: 0;
      left: 0;
      background: darken($azure, 10%);
    }
  }
}

.tableContainer {
  overflow-x: auto;

  &:not(:last-child) {
    margin: 0 0 10rem;
  }

  table {
    &.fixed-width {
      width: 100%;
      table-layout: fixed;
    }
  }
}

table {
	border-collapse: collapse;
	border-style: hidden;

	td {
		border: .1rem solid rgba($blue, .2);
		padding: 3rem;
		max-width: 60rem;
    vertical-align: top;
    
    @include mq(max, 480px) {
      padding: 2rem;
    }

		@include mq(max, 320px) {
			padding: 1.5rem;
		}

		p {
			margin-bottom: 1.5rem;
		}

		ul, ol {
      margin-bottom: 2rem;
      padding-left: 3rem;

			li {
				font-size: 1.4rem;
        line-height: 2.2rem;
        margin-bottom: .5rem;
			}
    }
    
    ul li {
      list-style-type: disc;
    }

    ol li {
      list-style-type: decimal;
    }
	}

	th {
		padding: 3rem;
		text-align: left;

		@include mq(max, 320px) {
			padding: 1.5rem;
		}
	}

	th,
	.accent {
		color: $blue;
		font-size: 1.8rem;
		line-height: 2.4rem;
    font-family: $nimbus;
    
    @include mq(max, 480px) {
      font-size: 1.5rem;
      line-height: 2.2rem;
    }
	}

  a {
    color: $navy;

    @include mq(min, 1024px) {
      @include slidingUnderline(darken($navy, 10%), .1rem)
    }
  }
}

.disclousure-icon {
  font-size: 1.5rem;
  vertical-align: top;
  line-height: 2rem;
  padding: 0 0 0 2px;
}