.links {
  padding: 6rem 0;

  > .primaryNav {
    @extend %commonNavStyles;
    margin: 0 0 2rem;

    > .item {
      @extend %transitionAll;
      font-size: 1.8rem;
      color: $navy;
      margin: 0 3.5rem 0 0;
      line-height: 2.2rem;

      @include mq(min, 768px) {
        @include slidingUnderline(lighten($navy, 10%), .1rem)
      }

      @include mq(max, 800px) {
        margin: 0 0 2rem 0;
      }

      &.-alignRight {
        margin: 0 0 0 auto;

        @include mq(max, 800px) {
          margin: 0 0 2rem 0;
        }

        svg {
          margin: 0 0 0 1rem;
        }
      }
    }
  }

  > .secondaryNav {
    @extend %commonNavStyles;

    > .item {
      @extend %transitionAll;
      font-size: 1.4rem;
      color: $silver;
      margin: 0 2.5rem 0 0;
      line-height: 2rem;

      @include mq(min, 768px) {
        @include slidingUnderline(darken($silver, 20%), .1rem)
      }

      @include mq(max, 800px) {
        margin: 0 0 2rem 0;
      }

      &:last-child {
        margin: 0;
      }
    }
  }
}