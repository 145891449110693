$siteNavHeight: 8rem;
$siteNavHeightMobile: 5rem;

.siteNav {
  height: $siteNavHeight;
  display: flex;
  width: 100%;

  @include mq(max, 768px) {
    height: $siteNavHeightMobile;
    align-items: center;
    justify-content: flex-end;
  }
  
  > .list {
    @extend %transitionAll;
    display: flex;
    width: 100%;

    @include mq(max, 768px) {
      flex-direction: column;
      align-items: center;
      position: absolute;
      left: 0;
      bottom: 0;
      right: 0;
      padding-top: 10rem;
      visibility: hidden;
      opacity: 0;
      z-index: 3;

      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: linear-gradient($blue, $navy);
      }
    }

    &[data-state='menu-collapsed'] {
      visibility: hidden;
      opacity: 0;

      @include mq(max, 768px) {
        top: -10rem;
      }
    }
  
    &[data-state='menu-expanded'] {
      visibility: visible;
      opacity: 1;

      @include mq(max, 768px) {
        top: 0;
        position: fixed;
      }
    }

    &.-whiteBg {
      @include mq(max, 768px) {
        &:before {
          background: linear-gradient($white, $azure);
        }
      }
    }

    > .dropdown {
      @include mq(max, 768px) {
        display: none;
      }
    }

    > .item {
      @extend %transitionAll;
      color: $white;
      margin: 0 0 0 3rem;
      line-height: $siteNavHeight;
      border-bottom: 0rem solid transparent;
      z-index: 1;

      @include mq(max, 768px) {
        line-height: $siteNavHeightMobile;
        margin: 0;
      }

      @include mq(min, 1024px) {
       &:hover:not(.dropdown) {
          border-bottom: .2rem solid $white;
        }
      }

      &.-active {
        color: rgba($white, .4);
        pointer-events: none;

        &.-whiteBg {
          color: $navy;
        }
      }

      &:last-child {
        margin-left: auto;
        position: relative;

        @include mq(max, 768px) {
          margin: 0;
        }

        &:before {
          content: '';
          height: $siteNavHeight;
          width: .1rem;
          background: rgba($white, .2);
          display: inline-block;
          position: absolute;
          left: -3rem;

          @include mq(max, 768px) {
            display: none;
          }
        }
      }

      &.-whiteBg {
        color: $silver;

        @include mq(min, 1024px) {
          &:hover:not(.dropdown) {
            border-bottom: .2rem solid $silver;
          }
        }

        &:last-child {
          &:before {
            background: rgba($silver, .2);
          }
        }
      }
    }
  }

  > .toggle {
    background: transparent;
    border: 0;
    z-index: 3;
    position: relative;
    padding: 0;
    width: 3rem;
    height: 2rem;

    @include mq(min, 768px) {
      display: none;
    }

    &:focus {
      outline: none;
    }

    > span {
      @extend %transitionAll;
    }

    &[data-state='menu-collapsed'] {
      > span {
        width: 100%;
        height: .2rem;
        background: $white;
        position: absolute;
        left: 0;

        &:nth-child(1) {
          top: 0;
        }

        &:nth-child(2),
        &:nth-child(3) {
          top: .9rem;
        }

        &:nth-child(4) {
          top: 1.8rem;
        }
      }
    }

    &[data-state='menu-expanded'] {
      position: fixed;
      top: 2.5rem;
      right: 2rem;

      > span {
        width: 100%;
        height: .2rem;
        background: $white;
        position: absolute;
        left: 0;

        &:nth-child(1),
        &:nth-child(4) {
          top: .9rem;
          transform: scale(0);
        }

        &:nth-child(2) {
          transform: rotate(45deg);
        }

        &:nth-child(3) {
          transform: rotate(-45deg);
        }
      }
    }

    &.-whiteBg {
      > span {
        background: $silver;
      }
    }
  }
}