$pad: 2rem;

.container {
  margin: 0 auto;
  height: 100%;
  width: 100%;

  &.-inner {
    max-width: 124rem;
    padding: 0 2rem;
    overflow: hidden;
  }

  &.-outer {
    // max-width: 144rem;
    max-width: 100%;
    overflow-x: hidden;
    position: relative;
    height: 100%;
  }
}

.row {
  display: flex;
  margin-left: -$pad;
  margin-right: -$pad;

  @include mq(max, 768px) {
    flex-direction: column;
  }
}

.col {
  padding: 0 2rem;

  &.-noPadding {
    padding: 0;
  }

  &.-twoThirds {
	  width: 66.666%;
  }

  &.-oneThird {
    width: 33.333%;
  }

  &.-oneHalf {
    width: 50%;
  }

  &.-oneQuarter {
    width: 25%;
  }

  &.-threeQuarters {
    width: 75%;
  }

  &.-threeFifths {
    width: 60%;
  }

  &.-twoFifths {
    width: 40%;
  }

  @include mq(max, 768px) {
    width: 100%;
  }
}
