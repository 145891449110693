.form {
  border: .1rem solid rgba($silver, .2);
  border-bottom: 0;
  border-radius: .3rem .3rem 0 0;
  overflow: hidden;

  > .field {
    @extend %transitionAll;
    position: relative;
    border-bottom: .1rem solid rgba($silver, .2);

    &[data-state-focus='off'] {
      background: $white;
    }

    &[data-state-focus='on'] {
      background: lighten($navy, 78%);
    }

    &[data-state-valid='false'] {
      background: rgba($red, .1);
    }

    > .label {
      @extend %transitionAll;
      position: absolute;
      left: 2.5rem;
      z-index: 0;
      font-weight: 300;

      @include mq(max, 600px) {
        left: 1.5rem;
        font-size: 1.2rem;
      }

      &[data-state-focus='off'] {
        color: rgba($silver, .5);
      }

      &[data-state-focus='on'] {
        color: $navy;
      }

      &[data-state-visibility='hidden'] {
        opacity: 0;
        visibility: hidden;
        top: 2rem;
      }

      &[data-state-visibility='visible'] {
        opacity: 1;
        visibility: visible;
        top: 1rem;

        @include mq(max, 600px) {
          top: .5rem;
        }
      }
    }

    > .input {
      @extend %transitionAll;
      border: 0;
      background: transparent;
      display: block;
      width: 100%;
      z-index: 1;
      position: relative;
      font-size: 2.6rem;
      color: $navy;
      letter-spacing: .05rem;
      font-weight: 300;
      font-family: $work;
      line-height: normal;
      padding: 0;
      padding: 3rem 2.5rem 3rem;

      @include mq(max, 600px) {
        padding: 2rem 1.5rem 2rem;
        font-size: 1.8rem;
      }

      @include placeholder {
        font-weight: 300;
        color: rgba($silver, .5);
      }

      &[data-state-fill='empty'] {
        padding: 3rem 2.5rem 3rem;

        @include mq(max, 600px) {
          padding: 2rem 1.5rem 2rem;
        }
      }

      &[data-state-fill='non-empty'] {
        padding: 3.75rem 2.5rem 2.25rem;

        @include mq(max, 600px) {
          padding: 2.75rem 1.5rem 1.25rem;
        }
      }

      &:focus {
        outline: none;
      }
    }
  }

  > .fieldGroup {
    display: flex;
    position: relative;

    &[data-state-valid='false'] {
      background: rgba($red, .1);

      .check {
        border-color: $red;
      }
    }

    > .errorMessage {
      top: .5rem;
      display: none; // TODO: figure out how to present error message for radio buttons
    }

    > .field {
      position: relative;
      width: 33.3333%;
      padding: 2.5rem;
      min-height: 6rem;

      &:not(:last-of-type) {
        border-right: .1rem solid rgba($silver, .2);
      }

      @include mq(max, 480px) {
        padding: 1rem;
      }

      @include mq(max, 375px) {
        display: flex;
        flex-direction: column;
      }

      > .input {
        position: absolute;
        visibility: hidden;

        &:checked ~ .label {
          color: $navy;
        }

        &:checked ~ .check {
          border: .1rem solid rgba($black, .3);
        }

        &:checked ~ .check::before{
          background: $black;
          transform: translate(-50%, -50%) scale(1);
        }
      }

      > .label {
        @extend %transitionAll;
        display: block;
        position: relative;
        font-weight: 300;
        font-size: 1.6rem;
        padding: 0 0 0 3rem;
        z-index: 9;
        cursor: pointer;
        line-height: 2rem;
        top: 50%;
        transform: translateY(-50%);
        user-select: none;

        @include mq(min, 1024px) {
          &:hover {
            ~ .check {
              background: lighten($navy, 78%);
            }
          }
        }

        @include mq(max, 480px) {
          font-size: 1.2rem;
          line-height: 1.6rem;
        }

        @include mq(max, 375px) {
          order: 2;
          padding: 0;
          text-align: center;
          top: auto;
          transform: none;
          margin-top: .5rem;
        }
      }

      > .check {
        @extend %transitionAll;
        z-index: 5;
        display: block;
        position: absolute;
        border: .1rem solid rgba($black, .1);
        border-radius: 100%;
        height: 2rem;
        width: 2rem;
        left: 2.5rem;
        top: 50%;
        transform: translateY(-50%);

        @include mq(max, 480px) {
          left: 1rem;
        }

        @include mq(max, 375px) {
          position: relative;
          order: 1;
          top: unset;
          left: unset;
          transform: none;
          margin: 0 auto;
        }

        &:before {
          @extend %transitionAll;
          display: block;
          position: absolute;
          content: '';
          border-radius: 100%;
          height: 1rem;
          width: 1rem;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%) scale(0);
          margin: auto;
        }
      }
    }
  }

  > .submit {
    /*
    hide submit inside form el and bind it
    with label '.fakeSubmit' outside form el for styling purposes
    */
    display: none;
  }

  ~ .fakeSubmit {
    @extend .button;
    @extend .button.-submit;
  }

  ~ .applyData {
    margin: 3rem 0 0;
    text-align: center;

    @include mq(min, 768px) {
      font-size: 2rem;
    }

    &.-success {
      color: green;
    }

    &.-fail {
      color: red;
    }

    &.-hidden {
      display: none;
    }
  }

  span {
    &.errorMessage {
      position: absolute;
      right: 2.5rem;
      top: 1rem;
      color: $red;
      z-index: 2;
      font-weight: 300;
    }
  }
}

