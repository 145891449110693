.dropdown {
  > .trigger {
    @extend %transitionAll;
    background: transparent;
    border: 0;
    display: flex;
    align-items: center;
    padding: 0;
    border-bottom: .2rem solid transparent;
    height: 8rem;

    &[data-state='expanded'] {
      border-bottom: .2rem solid $silver;
    }

    &:hover {
      cursor: pointer;
      border-bottom: .2rem solid $silver;
    }

    &:focus {
      outline: none;
    }

    > .text {
      @extend %commonMainNavItemStyles;
      color: $silver;

      &.-darkBg {
        color: $white;
      }
    }

    > .icon {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 0 0 1rem;

      &:before {
        @extend %transitionAll;
        content: '';
        display: block;
        height: 0;
        width: 0;
        border-top: .6rem solid $silver;
        border-left: .4rem solid transparent;
        border-right: .4rem solid transparent;
      }
      
      &[data-state='expanded'] {
        &:before {
          transform: rotate(180deg);
          border-top-color: $silver;
        }
      }
    }
  }

  > .content {
    @extend %transitionAll;
    display: flex;
    flex-direction: column;
    background: transparent;
    padding: 1.5rem;
    position: absolute;
    right: 0;
    bottom: 0;
    transform: translateY(100%) scale(0);
    border-radius: 0 0 .3rem .3rem;
    box-shadow: 0 1.5rem 3rem 0 rgba($black, .1);
    border: .1rem solid rgba($silver, .2);
    transform-origin: top right;

    &[data-state='expanded'] {
      transform: translateY(100%) scale(1);
    }

    &.-mainNav {
      border-top: 0;
    }

    &.-darkBg {
      border: .1rem solid rgba($white, .2);
      border-top: 0;
    }

    > .link {
      @extend %transitionAll;
      line-height: 1;
      white-space: nowrap;
      color: $silver;

      &:not(:last-child) {
        margin: 0 0 1.5rem;
      }

      &:hover {
        color: $navy;
      }

      &.-darkBg {
        color: $white;

        &:hover {
          color: rgba($white, .7);
        }
      }
    }
  }

  &.-darkBg {
    > .trigger {
      &:hover {
        cursor: pointer;
        border-bottom: .2rem solid $white;
      }

      &[data-state='expanded'] {
        border-bottom: .2rem solid $white;
      }

      > .text {
        color: $white;
      }

      > .icon {
        &:before {
          border-top: .6rem solid $white;
        }
        
        &[data-state='expanded'] {
          &:before {
            border-top-color: $white;
          }
        }
      }
    }

    > .content {
      border: .1rem solid rgba($white, .2);
      border-top: 0;

      > .link {
        color: $white;

        &:hover {
          color: rgba($white, .7);
        }
      }
    }
  }
}
