%contactLinksStyles {
  color: $navy;
  font-size: 2.4rem;
  font-weight: 300;
  letter-spacing: .05rem;
  display: inline-block;

  @include mq(max, 700px) {
    font-size: 1.8rem;
  }
}

.contact {
  @extend %commonAzureGradientBackground;

  > .container {
    > .inner {
      @extend %commonSectionStyles;

      &.-twoColumns {
        @extend %commonColumnStyles;
      }

      > .column {
        break-inside: avoid;
        margin: 0 0 2rem;

        > .headline {
          @extend %commonHeroHeadingStyles;
        }

        > .text {
          @extend %commonTextStyles;
        }

        > .link {
          @extend %contactLinksStyles;
          @include mq(min, 1024px) {
            @include slidingUnderline($navy, .1rem)
          }
        }

        > .group {
          &:not(:last-child) {
            margin: 0 0 5rem;
          }

          > .subheadline {
            @extend %commonThinHeadingStyles;
            margin: 0 0 2.5rem;
          }

          > .link {
            @extend %contactLinksStyles;
            @include mq(min, 1024px) {
              @include slidingUnderline($navy, .1rem)
            }

            &.-large {
              @extend %commonThinHeadingStyles;
            }
          }

          > label {
            display: block;
            margin: 0 0 2rem;
          }

          > .separator {
            height: 2rem;;
          }
        }
      }
    }
  }
}