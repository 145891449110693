.how {
  @extend .container;
  @extend .container.-inner;

  &.-customer {
    > .inner {
      @extend %commonSmallSectionPadding;

      > .heading {
        @extend %commonThinHeadingStyles;
      }

      > .step {
        display: flex;
        min-height: 9rem;

        &:not(:last-child) {
          margin: 0 0 4rem;
        }

        @include mq(max, 768px) {
          flex-direction: column;
          align-items: center;
        }

        > .number {
          width: 6rem;
          height: 6rem;
          color: $navy;
          font-size: 1.8rem;
          background: $azure;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          transform: translateY(-.5rem);
          flex-shrink: 0;
          flex-grow: 0;
        }

        > .title {
          @extend %commonThinHeadingStyles;
          margin: 0 0 0 4rem;
        }

        > .description {
          @extend %commonTextStyles;
          max-width: 50%;
          min-width: 28rem;
          margin: 0 0 0 auto;
        }

        > .number,
        > .title,
        > .description {
          @include mq(max, 768px) {
            margin: 0 0 3rem 0;
            text-align: center;
          }
        }
      }
    }
  }

  &.-retailers {
    > .inner {
      max-width: 55rem;
      margin: 0 auto;
      padding: 9rem 0;

      @include mq(max, 768px) {
        padding: 5rem 0;
      }

      > .heading {
        @extend %commonThinHeadingStyles;
      }

      > .text {
        @extend %commonTextStyles;
      }
    }
  }
}