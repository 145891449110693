$buttonWidthDesktop: 24rem;
$buttonWidthMobile: 22rem;

.button {
  @extend %transitionAll;
  width: $buttonWidthDesktop;
  height: 5rem;
  display: inline-flex;
  align-items: center;
  line-height: 5rem;
  font-size: 1.6rem;
  flex-shrink: 0;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  background: $blue;
  background-image: linear-gradient(to right, #1582e4 0%, #1890ee 51%, #279ff2 100%);

  @extend %commonTsP22UndergroundLight;

  @include mq(min, 768px) {
    &:hover,
    &:focus {
      transform: translateY(-5%);
      cursor: pointer;
    }
  }

  @include mq(max, 480px) {
    width: $buttonWidthMobile;
    height: 4rem;
    font-size: 1.4rem;
  }

  &:focus {
    outline: none;
  }

  &.-primary {
    color: $white;
    justify-content: center;
    box-shadow: 0 2rem 2rem 0 rgba($black, .2);

    @include mq(min, 768px) {
      &:hover,
      &:focus {
        box-shadow: 0 3rem 3rem 0 rgba($black, .1);
        background: darken($blue, 3%);
      }
    }
  }

  &.-secondary {
    color: $white;
    justify-content: space-between;
    padding: 0 2rem;

    @include mq(min, 768px) {
      &:hover,
      &:focus {
        box-shadow: 0 3rem 3rem 0 rgba($black, .1);
        background: darken($blue, 3%);
      }
    }

    svg {
      transform: rotate(-90deg);
    }
  }

  &.-tertiary {
    background: transparent;
    color: $navy;
    border: .1rem solid $navy;
    justify-content: space-between;
    padding: 0 2rem;

    @include mq(min, 768px) {
      &:hover,
      &:focus {
        background: $white;
      }
    }

    svg {
      transform: rotate(-90deg);

      polygon {
        fill: $navy;
      }
    }
  }

  &.-submit {
    @extend .button.-secondary;
    width: 100%;
    height: 9rem;
    font-size: 2.4rem;
    border-radius: 0 0 .3rem .3rem;
    padding: 0 2.5rem;

    @include mq(min, 768px) {
      &:hover,
      &:focus {
        transform: none;
      }
    }

    @include mq(max, 600px) {
      height: 6rem;
      font-size: 1.8rem;
      padding: 0 2rem;
    }
  }

  &.-disabled {
    pointer-events: none;
  }
}