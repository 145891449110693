.info {
  @extend .container;
  @extend .container.-inner;
  display: flex;

  @include mq(max, 800px) {
    flex-direction: column;
  }

  > .item {
    width: 33.33333%;
    height: 25rem;
    display: flex;
    align-items: center;

    @include mq(max, 800px) {
      width: 100%;
      justify-content: center;
      height: 15rem;
      text-align: center;
    }

    &.-support {
      @extend %commonThinHeadingStyles;
    }

    &.-contact {
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
    }

    &.-address {
      font-size: 1.4rem;
      color: $silver;
      line-height: 2.2rem;
    }

    > .link {
      display: block;
      font-size: 1.8rem;
      color: $navy;
      line-height: 2.2rem;
      
      &:not(:last-child) {
        margin: 0 0 1.5rem;
      }

      @include mq(min, 1024px) {
        @include slidingUnderline(lighten($navy, 10%), .1rem);
      }
      
      @include mq(max, 800px) {
        margin: 0 auto;

        &:not(:last-child) {
          margin: 0 auto 1.5rem;
        }
      }
    }
  }
}