%commonMobileLogoStyles {
  @include mq(max, 768px) {
    border: 0 !important;

    svg {
      width: 16rem !important;
    }
  }
}

.siteHeader {
  display: flex;
  border-bottom: .1rem solid rgba($white, .2);
  padding: 4rem 0 0;

  @include mq(max, 768px) {
    padding: 1rem 0 0;
  }

  &.-whiteBg {
    border-bottom: .1rem solid rgba($silver, .2);
  }

  > .logo {
    border-right: .1rem solid rgba($white, .2);
    display: flex;
    align-items: center;
    padding-right: 4rem;
    z-index: 4;
    max-width: 35rem;


    svg {
      width: 100%;
      max-width: 30rem;
      height: 100%;
      .cls-1 {
        fill: #fff;
      }
    }

    @extend %commonMobileLogoStyles;

    &.-whiteBg {
      border-right: .1rem solid rgba($silver, .2);
      @extend %commonMobileLogoStyles;
    }
  }

}

