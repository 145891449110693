.goUp {
  @extend %transitionAll;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  right: 2rem;
  bottom: 2rem;
  width: 4rem;
  height: 4rem;
  background: $white;
  border-radius: 50%;
  box-shadow: 0 0 2rem 0 rgba($black, .1);
  transform: scale(0);
  z-index: 1;

  @include mq(max, 480px) {
    width: 3rem;
    height: 3rem;
    right: 1.5rem;
    bottom: 1.5rem;
  }

  &[data-state='visible'] {
    transform: scale(1);
  }

  &[data-state='hidden'] {
    transform: scale(0);
  }

  > svg {
    transform: rotate(180deg);
    height: 1.5rem;

    > polygon {
      fill: $blue;
    }
  }
}