//colors
$white: #FFF;
$black: #000;
$blue: #007ee5;
$navy: #001E62;
$azure: #e2f4f9;
$aquamarine: #37B4D5;
$silver: #99A5C0;
$red : #d9534f;

$transition: all .25s ease-in-out;

//fonts
$nimbus: 'Nimbus Sans', sans-serif;
$work: 'Work Sans', sans-serif;
$tk-proxima-nova: "proxima-nova", sans-serif;
$tk-p22-underground: "p22-underground", sans-serif;
$tk-din-2014: "din-2014", sans-serif;