.apply {
  @extend .container;
  @extend .container.-inner;

  > .inner {
    @extend %commonSmallSectionPadding;
    display: flex;
    flex-direction: column;
    align-items: center;

    > .formWrapper {
      border: .1rem solid rgba($navy, .5);
      padding: 4.5rem;
      max-width: 65rem;
      margin: 9rem 0 0;

      @include mq(max, 600px) {
        border: 0;
        padding: 3rem 0;
        margin: 5rem 0 0;
      }

      > .heading {
        text-align: center;
        @extend %commonThinHeadingStyles;
      }

      > .subheading {
        font-family: $work;
        font-weight: 300;
        font-size: 1.8rem;
        color: $silver;
      }
    }
  }
}