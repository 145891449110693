.accordion {
  margin: 0 auto;

  > .revealer {
    border-bottom: .1rem solid rgba($navy, .1);
    overflow: hidden;

    > .trigger {
      display: flex;

      @include mq(min, 768px) {
        &:hover {
          cursor: pointer;

          > .heading {
            color: $navy;
          }
        }
      }

      &[data-state='expanded'] {
        > .heading {
          color: $navy;
        }
      }

      > .heading {
        @extend %transitionAll;
        @extend %commonTsProximaNovaRegular;
        font-size: 2.6rem;
        color: darken($silver, 10%);
        letter-spacing: .05rem;
        user-select: none;
        margin: 0;
        padding: 3.5rem 0;

        @include mq(max, 768px) {
          font-size: 2rem;
        }
      }

      > .icon {
        width: 5rem;
        margin: 0 0 0 auto;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;

        &:before {
          @extend %transitionAll;
          content: '';
          display: block;
          height: 0;
          width: 0;
          border-top: .6rem solid rgba($navy, .2);
          border-left: .4rem solid transparent;
          border-right: .4rem solid transparent;
        }
        
        &[data-state='expanded'] {
          &:before {
            transform: rotate(180deg);
            border-top-color: rgba($navy, .4);
          }
        }
      }
    }

    > .content {
      @extend %transitionAll;
      font-size: 1.4rem;
      color: $silver;
      visibility: hidden;
      opacity: 0;
      transform: translateY(-3rem);
      
      &[data-state='expanded'] {
        visibility: visible;
        opacity: 1;
        transform: translateY(0);
        margin: 0 0 3.5rem;
      }
    }
  }
}