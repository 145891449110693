.hero {
  background-repeat: no-repeat;
  height: 70rem;
  position: relative;
  padding: 0;
  overflow-x: unset;
  background-size: cover;

  @include mq(max, 480px) {
    height: 100vh;
  }

  &.-home {
    background-image: url('/static/img/hero-home.jpg');
    box-shadow: inset 0 0 0 40rem rgba($navy, .6);
    background-position: 40%;
  }

  &.-customer {
    height: 80rem;
    background:
      linear-gradient(
        rgba($black, .4),
        rgba($aquamarine, .4)
      ),
      url('/static/img/hero-personal-loans.jpg');
    background-position: center top;
    background-size: cover;

    .my-lease-icon {
      @extend %commonMyLeaseHeroIcon;
    }
  }

  &.-retailers {
    height: 80rem;
    box-shadow: inset 0 0 0 40rem rgba($black, .2);
    background-image: url('/static/img/hero-retailers.jpg');
    background-position: center;

    .my-lease-icon {
      @extend %commonMyLeaseHeroIcon;
    }
  }

  > .container {
    display: flex;
    flex-direction: column;

    > .scroll {
      @extend %commonScrollLinkStyles;
      color: $white;
      margin: auto 0 3rem;

      @include mq(max, 480px) {
        margin: auto 0 2rem;
      }
  
      &.-home {
        justify-content: flex-end;
      }
  
      > .mouseIcon {
        position: relative;
        display: inline-flex;
        margin-left: 1rem;
  
        &:before {
          content: '';
          height: 2rem;
          width: 1.2rem;
          border: .2rem solid $white;
          display: inline-block;
          border-radius: 3rem;
        }
  
        &:after {
          content: '';
          height: .4rem;
          width: .2rem;
          display: inline-block;
          position: absolute;
          border-radius: 50%;
          top: 0;
          left: 50%;
          transform: translate(-50%, 100%);
          background: $white;
        }
      }
    }

    > .header {
      padding: 4rem 0 0;
    }

    > .inner {
      max-width: 60rem;
      margin: 6vh 0 0;

      @include mq(max, 320px) {
        margin: 8vh 0 0;
      }
  
      > * {
        color: $white;
      }
  
      > .subtitle {
        font-weight: 300;
        font-size: 3.2rem;
        font-family: $work;
        letter-spacing: .1rem;
        margin: 0 0 2rem;

        @include mq(max, 768px) {
          font-size: 2.4rem;
        }

        @include mq(max, 480px) {
          font-size: 2rem;
          margin: 0 0 1.5rem;
        }

        @include mq(max, 320px) {
          font-size: 1.7rem;
          margin: 0 0 1rem;
        }
      }
  
      > .punchline {
        @extend %commonHeroHeadingStyles;
      }
  
      > .text {
        @extend %commonTsProximaNovaRegular;
        padding: 4rem 0 4rem;
        margin: 0;
        border-top: .1rem solid rgba($white, .2);
        max-width: 50rem;
        font-weight: 100;
        font-size: 1.8rem;

        @include mq(max, 320px) {
          padding: 1.5rem 0 1.5rem !important;
          font-size: 1.5rem;
        }
      }
  
      > .button {
        @extend %commonTsP22UndergroundLight;
        margin-right: 4rem;

        @include mq(max, 768px) {
          margin: 0 0 3rem;
        }

        @include mq(max, 480px) {
          margin: 0 0 2rem;
        }
      }

      &.-customer, &.-retailers {
        max-width: 65rem;
        > .text {
          max-width: 100%;
          @extend %commonTsProximaNovaRegular;
        }

        > .punchline {
          margin-top: 3rem;
        }

        > .text {
          padding: 3rem 0 3rem;
        }
        margin-top: 5vh;
        @include mq(max, 1024px) {
          margin-top: 4vh;
        }
        @include mq(max, 480px) {
          margin-top: 3vh;
        }
      }
      &.-retailers {
        max-width: 75rem;
      }
    }
  }
}