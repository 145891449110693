.features {
  &.-customers {
    @extend .container;
    @extend .container.-inner;

    h4.header {
      text-align: center;
      font-weight: normal;
      font-size: 3rem;
      margin: 7rem 0 2rem 0;
    }
    
    > .itemsWrapper {
      @extend %commonSmallSectionPadding;
      padding: 0;
      display: flex;
      flex-wrap: wrap;
      margin-left: -4.5rem;
      margin-right: -4.5rem;

      > .item {
        width: 33.333333%;
        text-align: center;
        padding: 0 4.5rem;
        margin: 0 0 3rem;

        @include mq(max, 1024px) {
          width: 50%;
        }

        @include mq(max, 480px) {
          width: 100%;
        }

        > .imgWrapper {
          height: 21.5rem;
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 0 0 3rem;

          > svg {
            max-height: 13rem;
          }
        }

        > .text {
          > .title {
            font-family: $work;
            font-size: 2.6rem;
            font-weight: 300;
            letter-spacing: .05rem;
          }

          > .description {
            @extend %commonTextStyles;
            margin: 0 0 1.2rem;
          }
        }
      }
    }
  }

  &.-customers {
    h4.header {
      @extend %commonTsP22UndergroundLight;
    }
    .itemsWrapper {
      > .item {
        .text {
          .description {
            @extend %commonTsP22UndergroundLight;
          }
        }
      }
    }
  }

  &.-retailers {
    position: relative;
    overflow: hidden;

    @include mq(max, 1500px) {
      overflow: visible;
    }
    
    > .background {
      position: absolute;
      top: 14rem;
      right: 50%;
      transform: translateX(50%) scale(1.2);
      width: 100%;

      @include mq(max, 768px) {
        opacity: .2;
      }
    }

    > .container {
      > .inner {
        @extend %commonSectionStyles;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        padding-bottom: 0;

        > .item {
          width: 50%;
          z-index: 1;
          
          &:not(:nth-child(1)) {
            margin: 0 0 20rem;

            @include mq(min, 1600px) {
              margin: 0 0 23rem;
            }

            @include mq(max, 768px) {
              margin: 0 0 7rem;
            }
          }

          &:nth-child(1) {
            margin: 0 0 10rem;
          }
          
          &:nth-last-child(-n+2),
          &:nth-last-child(-n+1) {
            margin: 0;
          }

          @include mq(max, 480px) {
            width: 100%;
            margin: 3rem 0;

            &.-dummy {
              display: none;
            }
          }

          @include mq(min, 480px) {
            &:nth-child(even) {
              padding: 0 0 0 2.5rem;
            }

            &:nth-child(odd) {
              padding: 0 2.5rem 0 0;
            }
          }

          @include mq(min, 768px) {
            &:nth-child(even) {
              padding: 0 0 0 5rem;
            }

            &:nth-child(odd) {
              padding: 0 5rem 0 0;
            }
          }

          > svg {
            width: 80%;
            height: auto;
          }

          > .heading {
            @extend %commonThinHeadingStyles;
            @extend %commonTsP22UndergroundLight;
          }

          > .text {
            @extend %commonTextStyles;

            > .navy {
              color: navy;

              > .large {
                @extend %commonThinHeadingStyles;
                vertical-align: middle;
              }
            }
          }

          > .list {
            padding: 0 0 0 3rem;

            > .node {
              @extend %commonTsProximaNovaRegular;
              font-size: 2.6rem;
              font-weight: 300;
              letter-spacing: .05rem;

              @include mq(max, 768px) {
                font-size: 2rem;
              }
              
              &:not(:last-child) {
                margin: 0 0 3rem;
              }

              > span {
                display: block;

                &:first-child {
                  color: $navy;
                }
              }
            }
          }
        }
      }
    }

    @include mq(min, 2100px) {
      > .container {
        > .inner {
          > .item {
            &:first-child {
              padding-bottom: 5rem;
            }
          }
        }
      }
    }

    &.-part-2 {
      > .container {
        > .inner {
          padding-top: 8.5rem;
        }
      }
    }
  }
}