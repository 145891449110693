.disclaimer {
  display: flex;
  flex-wrap: wrap;
  padding: 7rem 0;

  @include mq(max, 480px) {
    flex-direction: column;
    align-items: center;
    padding: 5rem 0;
  }

  > * {
    color: $silver;
  }

  > .logo {
    display: flex;
    max-width: 23rem;
    margin: 0 3rem 3rem 0;

    @include mq(max, 480px) {
      max-width: 15rem;
      margin: 0 0 2rem;
    }
    svg {
      width: 100%;
      max-height: 32px;
    }
  }

  > .copyright {
    margin: 0 0 2rem;

    @include mq(min, 480px) {
      width: calc(100% - 27rem);
    }
  }

  > .text {
    font-size: 1.2rem;
    line-height: 1.6rem;
    max-width: 50rem;

    > .link {
      color: lighten($navy, 10%);

      @include mq(min, 1024px) {
        @include slidingUnderline($navy, .1rem)
      }
    }
  }
}