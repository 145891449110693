.faq {
  @extend .container;
  @extend .container.-inner;

  > .inner {
    @extend %commonSmallSectionPadding;

    &.-retailers {
      max-width: 102.4rem;
      margin: 0 auto;
    }

    > .heading {
      @extend %commonThinHeadingStyles;
    }

    > .text {
      @extend %commonTextStyles;
    }

    > .subheading {
      color: rgba($silver, .7);
      font-weight: 300;
      padding: 0 0 1rem;
      border-bottom: .1rem solid rgba($navy, .1);
      margin: 0;
    }

    > .row {
      margin-left: -5rem;
      margin-right: -5rem;

      > .column {
        @extend .col;
        @extend .col.-oneHalf;
        padding: 0 5rem;
      }
    }
    &.-customer {
      padding-bottom: 8rem;
    }
  }
}